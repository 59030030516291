import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ActionPartial,
  ClassnamePartial,
  CodeSnippet,
  EnvironmentPartial,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  QaIdPartial,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const Notice = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Notice;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/notice.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <V5Notice
        code
        componentName="Notice"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/notifications-notice--docs"
      />
      <CodeSnippet
        scope={{ React: React, Notice: Notice }}
        code={snippet}
        platform="react"
        gitHubLink="notifications/notice"
      />
      <Section title="Props">
        <PropList header="Visual">
          <ActionPartial
            showButtonStyle
            showText
            showOnClick
            showHref
            showTarget
            showQaid
            showTooltip
          />

          <ClassnamePartial componentName="notice" />

          <PropListItem name="collapseMargin" types={['string']}>
            <Text>
              Removes the top, bottom, or vertical margin of the notice.
            </Text>
            <List type="unordered">
              <li>
                <code>top</code>
              </li>
              <li>
                <code>bottom</code>
              </li>
              <li>
                <code>vertical</code>
              </li>
            </List>
          </PropListItem>

          <EnvironmentPartial />

          <PropListItem name="header" types={['string']} isRequired>
            <Text>The header text at the top of the notice.</Text>
          </PropListItem>

          <PropListItem name="text" types={['ReactNode']} isRequired>
            <Text>
              The text that appears in the notice. To include a{' '}
              <Link href="/components/link" isDesignCodeLink>
                link
              </Link>
              , you'll need to wrap your text with the{' '}
              <Link href="/components/type/text" isDesignCodeLink>
                text
              </Link>{' '}
              component.
            </Text>
          </PropListItem>

          <PropListItem name="isTight" types={['boolean']}>
            <Text>Determines whether the Notice has tight spacing.</Text>
            <Text>
              <code>true</code> (default)
            </Text>
          </PropListItem>

          <PropListItem name="type" types={['string']}>
            <Text>Determines the type of notice.</Text>
            <List type="unordered">
              <li>
                <code>information</code>
              </li>
              <li>
                <code>confirmation</code>
              </li>
              <li>
                <code>warning</code>
              </li>
              <li>
                <code>critical</code>
              </li>
            </List>
          </PropListItem>
        </PropList>

        <PropList header="Events">
          <PropListItem name="onDismiss" types={['(e?) => void']} isRequired>
            <Text>Responds to the notice being dismissed.</Text>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <QaIdPartial
            componentName="notice"
            passedDown={['dismiss', 'text']}
          />
        </PropList>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React"
        componentName="Notifications / Notice"
      />
    </PlatformTab>
  );
};

export default WebTab;
