import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  CodeSnippet,
  Link,
  List,
  PlatformTab,
  PropList,
  PropListItem,
  Section,
  AccessibilityLabelPartial,
  TestIdPartial,
} from '../../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  },
});

const snippet = require('raw-loader!../../../../data/snippets/notice.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <CodeSnippet
        code={snippet}
        platform="react-native"
        gitHubLink="notifications/notice"
        disableCodeEditing
      />
      <Section title="Props">
        <PropList header="Visual">
          <PropListItem name="actions" types={['Action[]']}>
            <Text>Subtle buttons that show beneath the notice message.</Text>
            <List type="unordered">
              <li>
                <code>text</code> - the text to display in the button
              </li>
              <li>
                <code>onPress</code> - func to pass in to handle button press
              </li>
            </List>
          </PropListItem>

          <PropListItem name="header" types={['string']}>
            <Text>The title text at the top of the notice.</Text>
          </PropListItem>

          <PropListItem name="text" types={['string']}>
            <Text>
              The text that appears in the notice. To include a{' '}
              <Link href="/components/link" isDesignCodeLink>
                link
              </Link>
              , you'll need to wrap your text with the{' '}
              <Link href="/components/type/text" isDesignCodeLink>
                text
              </Link>{' '}
              component.
            </Text>
          </PropListItem>

          <PropListItem name="type" types={['string']} isRequired>
            <Text>Determines the type of notice.</Text>
            <List type="unordered">
              <li>
                <code>information</code>
              </li>
              <li>
                <code>confirmation</code>
              </li>
              <li>
                <code>warning</code>
              </li>
              <li>
                <code>critical</code>
              </li>
            </List>
          </PropListItem>
        </PropList>

        <PropList header="Events">
          <PropListItem name="onDismiss" types={['() => void']}>
            <Text>Responds to the notice being dismissed.</Text>
          </PropListItem>
        </PropList>

        <PropList header="Miscellaneous">
          <AccessibilityLabelPartial componentName="notice" />

          <TestIdPartial componentName="notice" />
        </PropList>
      </Section>

      <AccessibilityAuditComponentResultsSection
        platform="React Native"
        componentName="Notifications / Notice"
      />
    </PlatformTab>
  );
};

export default ReactNativeTab;
